// -- The Link Resolver
// This function will be used to generate links to Prismic documents
// As your project grows, you should update this function according to your routes

export const linkResolver = ({ node, key, value }) => doc => {
  if (doc.type === 'project_template') {
    return '/projects/' + doc.uid;
  }

  if (doc.type === 'contact_information' || doc.type === 'contact_form') {
    return `/contact`;
  }

  if (doc.type === 'page_template') {
    return `/${doc.uid}`;
  }

  if (doc.type === 'navigation') {
    return `/`;
  }

  // Homepage route fallback
  return '/';
};
