import React from 'react';
import { withPreviewResolver } from 'gatsby-source-prismic';
import { linkResolver } from '../utils/linkResolver';

import Layout from '../components/layout';
import HeroSection from '../components/HeroSection';

const PreviewPage = ({ isPreview, previewData, isLoading, path }) => {
  if (isPreview === false) return 'Not a preview!';

  return (
    <Layout>
      <HeroSection header="Preview Loading"></HeroSection>
    </Layout>
  );
};

export default withPreviewResolver(PreviewPage, {
  repositoryName: 'centreland',
  linkResolver,
});
